<script>
import Layout from "@/layouts/main.vue";
import appConfig from "@/app.config";
import "@vueform/multiselect/themes/default.css";
import PageHeader from "@/components/page-header";
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";
import _ from "lodash";
import Multiselect from "@vueform/multiselect";
import exportExcel from "@/helpers/exportExcel.js";

export default {
  page: {
    title: "Result Transmission",
    meta: [
      {
        name: "Result Transmission",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      modalTitle: "",
      fileList: {},
      checkAll: false,
      title: "List",
      items: [
        {
          text: "Result Transmission",
          href: "/",
        },
        {
          text: "List",
          active: true,
        },
      ],
      searchQuery: null,
      defaultOptions: {
        animationData: animationData,
      },
      sliderCustomzie: [1000, 3000],
      userData: [],
      pages: [],
      page: 1,

      UserDetailsData: {},
      formData: {
        name: "",

        avatar: "",
        phone: "",

        email: "",
        role_id: "",
      },
      table_options: {
        current_page: 1,
        page_size: 20,
        search_text: "",
        sort: { column: "", direction: "" },
      },
      meta: {},


    };
  },

  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    //EXPORT USERS
    exportUsers() {
      this.$store.dispatch("result/exportResults").then((res) => {
        exportExcel(res, "user-list.xlsx");
      });
    },

    //open edit User
    openEditClient(data) {
      this.modalTitle = "Edit User";
      this.formData = {
        ...data,
      };
    },
    //add and edit User data
    openUserModal() {
      Object.keys(this.formData).forEach((key) => (this.formData[key] = ""));
      this.modalTitle = "Add User";
    },

    //get all Users
    getUsers() {
      this.$store
        .dispatch("result/getResults", this.table_options)
        .then((res) => {
          console.log(res);
          this.userData = res.data;
          this.meta.total = res.total;
          this.meta.from = res.from;
          this.meta.last_page = res.last_page;
        })
        .catch((err) => console.log(err));
    },

    debounceInput: _.debounce(function (e) {
      this.table_options.search_text = e.target.value;
      this.$store
        .dispatch("result/getResults", this.table_options)
        .then((response) => {
          this.userData = response.data;
          this.meta.total = response.total;
          this.meta.from = response.from;
          this.meta.last_page = response.last_page;
        });
    }, 500),

    handlePageChange(e) {
      this.table_options.current_page = e;
      this.$store
        .dispatch("result/getResults", this.table_options)
        .then((response) => {
          this.userData = response.data;
          this.meta.total = response.total;
          this.meta.from = response.from;
          this.meta.last_page = response.last_page;
        });
    },
  },
  mounted() {
    this.getUsers();
  },

  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    Multiselect,
  },
};
</script>

<template>
  <Layout>
    <!-- end table responsive -->
    <PageHeader :title="title" :items="items" />
    <!-- ADD CLIENT MODAL -->
    <div
      class="modal fade"
      id="showModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <form action="#" id="addform" @submit.prevent="addUser">
            <div class="modal-body">
              <div class="mb-3" id="modal-id">
                <label for="name" class="form-label">First Name</label>
                <input
                  type="text"
                  v-model="formData.name"
                  id="name"
                  class="form-control"
                  placeholder="Enter Name"
                  required
                />
              </div>

              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input
                  type="email"
                  v-model="formData.email"
                  id="email"
                  class="form-control"
                  placeholder="Enter Email"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">Phone</label>
                <input
                  type="tel"
                  v-model="formData.phone"
                  id="phone"
                  class="form-control"
                  placeholder="Enter phone"
                  required
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="role">Role</label>
                <Multiselect
                  required
                  class="form-control"
                  id="role"
                  v-model="formData.role_id"
                  :close-on-select="true"
                  :searchable="true"
                  :create-option="true"
                  :options="userRoles"
                />
              </div>
              <div class="mb-3" v-if="modalTitle !== 'Edit User'">
                <label for="avatar" class="form-label">Avatar</label>
                <input
                  type="file"
                  @change="handleAvatar"
                  ref="userAvatar"
                  id="avatar"
                  class="form-control"
                />
              </div>
              <div class="mb-3" v-if="modalTitle == 'Edit User'">
                <label for="avatar" class="form-label">Status</label>
                <Multiselect
                  required
                  class="form-control"
                  id="role"
                  v-model="formData.status_id"
                  :close-on-select="true"
                  :searchable="true"
                  :create-option="true"
                  :options="[
                    {
                      label: 'Active',
                      value: 1,
                    },
                    {
                      label: 'Inactive',
                      value: 0,
                    },
                  ]"
                />
              </div>
            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                  id="closemodal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-success" id="add-btn">
                  {{ modalTitle }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- END ADD User MODAL -->

    <!-- User DETAILS -->
    <div
      class="modal fade"
      id="showClientDetailModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel">User Details</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-borderless mb-0">
                <tbody>
                  <tr>
                    <th class="ps-0" scope="row">User :</th>
                    <td class="text-muted">{{ UserDetailsData.name }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Email :</th>
                    <td class="text-muted">{{ UserDetailsData.email }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Phone :</th>
                    <td class="text-muted">{{ UserDetailsData.phone }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Role :</th>
                    <td class="text-muted">
                      {{ UserDetailsData.role && UserDetailsData.role.name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Status:</th>
                    <td class="text-muted">
                      <span
                        class="badge text-uppercase"
                        :class="[
                          UserDetailsData.status_id === 1
                            ? 'badge-soft-success'
                            : 'badge-soft-danger',
                        ]"
                        >{{
                          UserDetailsData.status_id === 1
                            ? "Active"
                            : "Inactive"
                        }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
                id="closemodal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END CLIENT DETAILS -->
    <div class="row">
      <div class="col-md-12">
        <div>
          <div class="card">
            <div class="card-header border-0">
              <div class="row g-4">
                <div class="col-sm-auto">
                  <div>
                    <button
                      type="button"
                      @click="exportUsers"
                      class="btn btn-primary m-2 add-btn"
                    >
                      Export
                    </button>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="d-flex justify-content-sm-end">
                    <div class="search-box ms-2">
                      <input
                        type="text"
                        @input="debounceInput"
                        class="form-control"
                        placeholder="Search ..."
                      />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <ul
                    class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active fw-semibold"
                        data-bs-toggle="tab"
                        href="#Usernav-all"
                        role="tab"
                      >
                        All

                        <span
                          class="badge badge-soft-danger align-middle rounded-pill ms-1"
                          >{{ meta.total }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">
                    <div class="my-n1 d-flex align-items-center text-muted">
                      Select
                      <div
                        id="select-content"
                        class="text-body fw-semibold px-1"
                      ></div>
                      Result
                      <button
                        type="button"
                        class="btn btn-link link-danger p-0 ms-3 shadow-none"
                        data-bs-toggle="modal"
                        data-bs-target="#removeItemModal"
                        @click="deleteMultiple"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content text-muted">
                <div class="tab-pane active" id="Usernav-all" role="tabpanel">
                  <div
                    id="table-User-list-all "
                    class="table-card gridjs-border-none table-responsive"
                  >
                    <table
                      class="table align-middle table-nowrap mb-0"
                      id="customerTable"
                    >
                      <thead class="table-light">
                        <tr>
                          <th scope="col" style="width: 50px">

                          </th>
                          <th>Region ID</th>

                          <th>Region Name</th>
                          <th>Prez
                          NDC</th>
                          <th>Prez NPP</th>
                          <th>Population</th>
                          <th>Population</th>
                          <th>Population</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody class="list form-check-all">
                        <tr
                          class="gridjs-tr"
                          v-for="(data, index) of userData"
                          :key="index"
                        >
                          <td
                            data-column-id="UserListAllCheckbox"
                            class="gridjs-td"
                          >

                          </td>
                          <td class="id" hidden>
                            <a href="">{{ data.id }}</a>
                          </td>
                          <td data-column-id="name" class="gridjs-td">
                            {{ data.station.stationcode }}
                          </td>

                          <td data-column-id="email" class="gridjs-td">
                            {{ data.station.stationname }}
                          </td>


                          <td data-column-id="email" class="gridjs-td">
                            {{ data.station.constname }}
                          </td>


                          <td data-column-id="email" class="gridjs-td">
                            {{ data.station.regionname }}
                          </td>
                          <td data-column-id="phone" class="gridjs-td">
                            {{ data.prezndcvotes }}
                          </td>
                          <td data-column-id="role" class="gridjs-td">
                                                      {{ data.preznppvotes }}
                          </td>
                          <td data-column-id="status_id" class="gridjs-td">

                              {{ data.palndcvotes }}

                          </td>
                          <td data-column-id="status_id" class="gridjs-td">

                            {{ data.palnppvotes }}

                          </td>
                          <td data-column-id="action" class="gridjs-td">
                            <span>
                              <div class="dropdown">
                                <button
                                  class="btn btn-soft-secondary btn-sm dropdown"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i class="ri-more-fill"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      data-bs-toggle="modal"
                                      id="details-btn"
                                      data-bs-target="#showClientDetailModal"
                                      @click="UserDetailsData = { ...data }"
                                      class="dropdown-item"
                                    >
                                      <i
                                        class="ri-eye-fill align-bottom me-2 text-muted"
                                      ></i>
                                      View
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      @click="openEditClient(data)"
                                      class="dropdown-item"
                                      data-bs-toggle="modal"
                                      id="edit-btn"
                                      data-bs-target="#showModal"
                                    >
                                      <i
                                        class="ri-pencil-fill align-bottom me-2 text-muted"
                                      ></i>
                                      Edit
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      @click="handlePageChange(table_options.current_page)"
                      class="d-flex mt-4 mx-3 align-items-center justify-content-end"
                    >
                      <b-pagination
                        v-model="table_options.current_page"
                        :total-rows="meta.total"
                        :per-page="table_options.page_size"
                        prev-text="Prev"
                        next-text="Next"
                        prev-class="prev"
                        next-class="next"
                        class="pagination-wrap hstack gap-2"
                      >
                        <template #prev-text>
                          <div
                            class="page-item shadow-sm pagination-prev disabled"
                          >
                            Previous
                          </div>
                        </template>

                        <template #next-text>
                          <div class="page-item shadow-sm pagination-next">
                            Next
                          </div>
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="Usernav-published" role="tabpanel">
                  <div
                    id="table-User-list-published"
                    class="table-card gridjs-border-none"
                  ></div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="Usernav-draft" role="tabpanel">
                  <div class="py-4 text-center">
                    <div>
                      <lottie
                        class="avatar-xl"
                        colors="primary:#121331,secondary:#08a88a"
                        :options="defaultOptions"
                        :height="75"
                        :width="75"
                      />
                    </div>

                    <div class="mt-4">
                      <h5>Sorry! No Result Found</h5>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
